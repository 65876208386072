.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tag-list {
  position: absolute;
  z-index: 1;
  top: 100px;
  left: 14px;
  background: white;
  border: 1px solid black;
  border-radius: 14px;
  padding: 10px;
  width: 150px;
  color: white;
  text-align: center;
}
.tag-list h2 {
  margin: 5px;
  color: black;
}
#tagSelect {
  width: 100%;
}

.logo-dashboard{
  text-align: center;
  height: 100vh;
  display: inline-grid;
  align-items: center;
  width: 100%;
}

.device-dashboard{
  color: white;
}

.logo {
  width: 450px;
}

.map-logo {
  width: 100px;
}

body {
  margin: 0px;
}